:root {
  --primary: #007CDE;
  --secondary: #02AF95;
}

/* body {
  width: 100% !important;
  display: inline-block !important;
  min-width: auto !important;
  object-fit: contain;
} */

html, body{
  height:100%;
}
body{
  min-height:100%;
  overflow-x: hidden !important;
  width: 100% !important;
  min-width: 100% !important;
  overflow-wrap: break-word;
}
/* -----------------------------------
    HERO SECTION
------------------------------------*/
.background-video{
    width               : 100%;
    height              : 100vh;
    min-width           : auto !important;
    position            : absolute;
    left                : 0;
    top                 : 0;
    background-position : absolute;
    object-fit          : cover;
    z-index             : -5;
    
}

.hero {
    height              :100vh;
    background          : #00000065;
}

.sub-title-h2{
  font-size: 25px!important;
}

.sub-title{
  font-size: 14px;
  font-weight: 400;
}

.input-form-line{
  border-color: #0FBEA3;
  border-radius: 5px;
}

.count-result {
  font-size: 3rem;
  color: var(--secondary);
  display: block;
}

.img-size {
  width: 40%;
}



@media (max-width: 960px) {
  .count-result {
    font-size: 2.5rem;
    color: var(--secondary);
    display: block;
  }
  .img-size {
    width: 50%;
  }
}

@media (max-width: 660px) { 
  .img-size {
    width: 60%;
  }
}

@media (max-width: 560px) { 
  .count-result {
    font-size: 2rem;
    color: var(--secondary);
    display: block;
  } 
  .img-size {
    width: 70%;
  }
}

.count-category {
  font-size: 14px;
  font-weight: 100;
}

.btn-style, .btn-style2 {
  font-size: 1.3vw !important;
  padding: 5px 10px;
  letter-spacing: 1px;
}

.btn-style:hover {
  background-color: var(--secondary) !important;
  border: 0px solid var(--secondary);
}

.btn-style2:hover {
  background-color: var(--primary) !important;
  border: 0px solid var(--primary);
}

.header-border-secondary {
  color: var(--secondary);
  border: 1px solid;
  margin-bottom: 0!important;
  opacity: 1!important;
}

.header-border-primary {
  color: var(--primary);
  border: 1px solid;
  margin-bottom: 0!important;
  opacity: 1!important;
}

.border-width-long{
  width: 100px!important;
}

.font-size-big{
  font-size: 21px!important;
}
  
  
  #ECOService, #RentalCost, #PickEV {
    scroll-margin-top: 50px;
    scroll-behavior: smooth;
  }

.border-width-short{
  width: 50px!important;
}

.text-primary {
  color: var(--primary)!important;
}
.text-secondary {
  color: var(--secondary)!important;
}

/* -----------------------------------
    NAVBAR
------------------------------------*/
.scale-down-bottom {
	-webkit-animation: scale-down-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-up-top {
	-webkit-animation: scale-up-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
  }
  
  .fade-in2 {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
  }
  
  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  .fade-out {
    animation: out ease 2s;
    -webkit-animation: out ease 2s;
    -moz-animation: out ease 2s;
    -o-animation: out ease 2s;
    -ms-animation: out ease 2s;
  }
  
  
  @keyframes out{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes out {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes out {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes out {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes out {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  .subNav div div a{
    color:#000;
    text-decoration: none;
    text-transform: lowercases;
    font-size: 14px;
   
  }

  .active div a {
    color: var(--secondary) !important;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--secondary);
  }

  .borderRight {
    margin:10px 0px;
    border-right: 2px solid rgb(167, 167, 167);
    height: auto;
  }
  .borderRight:nth-last-child(1){
  border-right: 0px solid rgb(167, 167, 167) !important;
  }

  @media (max-width: 992px) {
    .borderRight:nth-child(even){
      border-right: 0px solid rgb(167, 167, 167) !important;
      }
    .pick-ev-img {
      height: 250px!important;
    }
  }

  @media (max-width: 1200px) {
    .pick-ev-img {
      height: 400px;
    }
  }
  @media (min-width: 1200px) {
    .pick-ev-img {
      height: 500px;
    }
  }


  .space {
    letter-spacing: 1px;
    font-size: 0.7em;
  }
  /* -----------------------------------
   HOME SECTIONS
------------------------------------*/
.cardHover:hover {
  color: #FFFFFF;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(15,190,163,1) 48%, rgba(15,190,163,1) 100%);
}

.ev_pickup_span {
  margin-left: 5px;
  font-size: 16px;
  margin-top: 5px;
  color: #02E2BF;
}

.ev_pick_price {
  font-size: 23px;
  color: #02E2BF;
}

.ev_pick_m3 {
  font-size: 20px;
  color: #02E2BF;
}

.ev_pick_button {
  font-size: 14px;
  color: #0FBEA3; 
  border-color: #0FBEA3!important;
  border-radius: 15px;
  padding: 6px 25px;
  background: transparent;
  width: 80%;
}

.ev_pick_button:hover{
  background-color: #02AF95!important;
  color: #fff!important;
}

.ev_pickup_cardbody {
  background-color: #eae8e838;
  width: 100%;
}

.ev_pickup_cardbody a {
  color: rgb(33 37 41)!important;
}

.margin_top_5 {
  margin-top: 5px;
}


.pickup-icon{
  font-size: 16px;
   color: #02E2BF;
}

.cardHover:hover .pickup-icon{
   color: #fff;
}

.cardHover:hover .ev_pick_price{
  color: #fff;
}

.cardHover:hover .ev_pick_m3{
  color: #fff;
}

@media screen and (max-width: 992px) { 
  .ev_detail_table td {
    padding-bottom: 0px!important;
  }
  .ev_detail_table {
    font-size: 14px!important;
  }
  .ev_detail_icon {
    font-size: 15px!important;
  }
  .ev_detail_per_price {
    font-size: 18px!important;
  }
  .ev_detail_header {
  margin-bottom: 10px!important;
  font-size: 18px!important;
    
  }
}

.ev_detail_table {
  font-size: 18px;
}
.ev_detail_icon {
  font-size: 18px;
  color: #0FBEA3;
}
.ev_detail_per_price {
  font-size: 23px;
  color: #0FBEA3 ;
}
.ev_detail_table td {
  padding-bottom: 20px;
}
.ev_detail_header {
  border-bottom: 2px solid #0FBEA3 ;
  margin-bottom: 20px;
}

.cardRentalHover:hover {
  color: #FFFFFF;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 5%, rgba(15, 190, 164, 0.527) 100%);

}
.hoverText {
  display: none;
}
.photo-content p, .photo-content span{
  text-decoration: none!important;
}

.cardRentalHover:hover .notHoverText {
 display: none;
}

.cardRentalHover:hover .hoverText {
  display: block;

 }

 .cardEcoHover:hover .notHoverText {
  display: none;
 }
 
 .cardEcoHover:hover .hoverText {
   display: block;
   text-decoration: none!important;
  }

.cardEcoHover:hover {
  color: #FFFFFF;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 5%, rgba(0,124,222,1) 100%);
}

.border-end-style {
  border-right: 1px solid rgb(190, 190, 190);
}

@media (max-width: 800px) {
  .border-end-style {
    border-right: 0px solid rgb(190, 190, 190);
  }
}

/* -----------------------------------
    icons
-------------------------------------*/
.iconStyle {
  border: 2px solid #000;
  border-radius:50%;
  width:50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconStyleBlog {
  border: 2px solid #000;
  border-radius:50%;
  width:35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconStyleFooter {
  border: 2px solid var(--secondary);
  border-radius:50%;
  width:30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* -----------------------------------
    DRIVE WITH US
-------------------------------------*/

.driveWith-background {
  background-image: url('../img/Smoothdrive/smoothdrive.png');
  height:70vh;
  width:100%;
  background-size: cover;
  background-position: top;
  position: relative;
}

.driveWith-background-drive {
  background: url('../img/driveWithUs/20211105_143324.jpg'), linear-gradient(27deg, rgba(0, 0, 0, 0.945) 0%, rgba(0,0,0,0.87718837535014) 55%, rgba(0,212,255,0) 100%);
  width:100%;
  background-size: cover;
  background-position: top;
  position: relative;
  background-blend-mode: multiply;
}

.inner-btn  {
  position: absolute;
  bottom:30px;
  right: 10%;
}

.btn-apply {
  border-radius: 50px;
  background-color:#0FBEA3;
  border: 0px solid red;
}

.learn-more {
  text-decoration: none;
  color: #0FBEA3;
}
/* -----------------------------------
    BLOGS
-------------------------------------*/

.blog-img-wrapper {
  height: 200px;
  background-color: #e0e0e0;
  position: relative;
  overflow: hidden;
  /* object-fit: contain !important; */
}

.blog-img-wrapper-internal {
  height: 92px;
  width: 8em;
  background-color: #e0e0e0;
  position: relative;
  overflow: hidden;
  object-fit: contain !important;
}

.border-shadow  {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.blog-img-wrapper2 {
  height: 200px;
  width:100%;
  background-color: #c0c0c057;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  /* object-fit: contain !important; */
}

.pickEv-img-wrapper2 {
  height: 200px;
  width:100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
  overflow: hidden;
  background-color: #c0c0c057;;
}
.insight-img-wrapper {
  height: 200px;
  width:100%;
  background-color: #afafaf57;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  /* object-fit: contain !important; */
}

.blog-detail-wrapper {
  height: 400px;
  /* background-color: #0fbea457; */
  position: relative;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  /* object-fit: contain !important; */
}

.blog-img {
  position: absolute !important;
  height:100%;
  width: 100%;
  object-fit: contain !important;
}

.pickev-img {
  height:100%;
  width: 100%;
  object-fit: contain !important;
}

.insight-img {
  position: absolute !important;
  height:100%;
  width: 100%;
  object-fit: contain !important;
}

.hover01 img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 2.5s ease-in-out;
	transition: 2.5s ease-in-out;
}
.hover01 img:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

/* -----------------------------------
    Input Buttons
-------------------------------------*/
.custom-file-input::-webkit-file-upload-button {
  display: none;
}

.custom-file-input::before {
  content: 'Upload CV here';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  padding: 5px 14px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 600;
  font-size: '14px';
  color: #0FBEA3;
  border-color: #0FBEA3;
  border-radius: 15px;
  margin-right: 10px;
  
}
.custom-file-input:hover::before {
  border-color: #079782;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* -----------------------------------
   SLIDER (CUSTOMER SATIFICATION)
------------------------------------*/

@media (max-width: 1024px) {
  .ads-banner {
    display: none;
  }
}

@media (max-width: 800px) {
  .bg-image-WhyEV {
    display: none;
  }
}

/* -----------------------------------
   SLIDER (CUSTOMER SATIFICATION)
------------------------------------*/
  
  .message-pf {
    max-width: 100%;
    height: 80%;
    /* border: 2px solid var(--primary); */
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;
  }
  
  .message-pf img {
    max-width: 70%;
  }
  .message-t {
    padding: 20px 30px 30px 70px;
    color: #000000 !important;
    display: inline-block;
  }

  .charging-table-ac-row {
    background-color: #E0F7F3;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 50px;
    border-bottom-right-radius: 8px;
  }

  .margin-top-5 {
    margin-top: 5px;
  }
  
  .message-leftrow {
    background-image: url('../img/sliders/slide-message.png');
    background-size: 95% 70%;
    background-repeat: no-repeat;
    padding-bottom: 100px;
    display: block;
    /* filter: drop-shadow(0px 2px 3px rgba(44, 44, 44, 0.85)); */
  }

/* -----------------------------------
  ABOUT SECTION
------------------------------------*/

@media only screen and (max-width: 770px) {
    .divReverse {
        display: flex;
        flex-wrap: wrap-reverse;
    }
    .padding {
        margin-top: 3rem ;
    }
}

/* ECO Service Section */
@media screen and (max-width: 950px) {
.outer {
    height: 100vh;
    max-height: 400px !important;
    min-width: 20vw;
    flex: 1;
    margin-bottom: 10px;
}
}

@media screen and (max-width: 450px) {
.outer {
    height: 100vh;
    max-height: 450px !important;
    min-width: 20vw;
    flex: 1;
    margin-bottom: 50px;
}
}

.outer {
    height: 100vh;
    max-height: 350px;
    min-width: 20vw;
    flex: 1;
}
.progress {
    display: inline-flex;
    height: 100%;
    padding: 5vh 10%;
    overflow: visible;
    background-color: transparent;
}

.progress > div {
    display: flex;
    flex-direction: column;
    color: rgb(29, 29, 29);
}

.progress > div.right {
    padding-left: 20px; 
}
.progress > div.right div.prev:after {
    transition: none;
}
.progress > div.right div.current {
    color: var(--primary);
    font-weight: bold;
}

.progress > div.right div:before {
    content: "";
    background: var(--primary);
    padding: 8px;
    border-radius: 50%;
    position: absolute;
    left: -20px;
    top: 10px;
    transform: translateX(-50%) translateY(-50%);
    transition: padding 0.2s ease;
}

.progress > div.right div:after {
    content: "";
    background: var(--primary);
    /* border-radius: 2px; */
    position: absolute;
    left: -20px;
    top: 10px;
    height: 101%;
    width: 2px;
    transform: translateX(-50%);
    transition: height 0.2s ease;
}

.progress > div.right .right-end:after {
  content: "";
  background: var(--primary);
  /* border-radius: 2px; */
  position: absolute;
  left: -20px;
  top: 10px;
  height: 0%;
  width: 2px;
  transform: translateX(-50%);
  transition: height 0.2s ease;
}

.progress > div div {
    flex: 1;
    position: relative;
    line-height: 20px;
    cursor: default;
    min-height: 30px;
}

.progress > div div:last-of-type {
    flex: 0;
}

.progress-text {
    font-size: 16px;
    line-height: 20px;
}

.bg-image-WhyEV {
  position: fixed;
  /* width: 60vh; */
  /* height: 110%; */
  height: auto;
  max-height: 100%;
  background: #a84909;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  right:0;
  bottom: 0;
  top: 0;
  z-index: -100;
  overflow: hidden;
}

.min-height {
   min-height: 100vh;
}
.bg-image-WhyEV > img{
   width: 100%;
   height:100%;
}

.career-style {
  border: 1px solid rgb(90, 90, 90);
  color:rgb(90, 90, 90);
  border-radius: 8px;
  padding: 5px 15px;
}

.carrer-title {
  border-radius: 8px;
  padding:5px 10px;
}

.career-style:hover {
  transform: translateY(-0.25em);
  box-shadow: 0 0 0.5em 0em #5e5e5e;
  border-color: #5e5e5e;
  color: #5e5e5e;
}

.footer-bottom {
  bottom: 0;
}


/*--------------------------------
#   Charging Calculator
---------------------------------*/
    
.btn-active {
    color: #FFFFFF;
    background: var(--primary);
    font-size: 14px;
    padding: 3px 15px;
    margin-left: 10px;
    border-radius: 20px;
}

.btn-active:hover {
    background: #007CDEdd;
    box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, .2);
  }

.btn-active-kw {
    color: #FFFFFF;
    background: var(--secondary);
    font-size: 0.7em;
    padding: 0.5em 2em;
    margin-left: 0.5em;
    border: 0px solid #cccccc;
    border-radius: 20px;
}

.btn-active-kw:hover {
    box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, .2);
  }

.btn-month {
    color: #FFFFFF;
    background: var(--primary);
    font-size: 14px;
    padding: 3px 15px;
    margin-left: 10px;
    border-radius: 20px;
}

.btn-month:hover {
    background: #007CDEdd;
    box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, .2);
  }

.tag {
    color: #FFFFFF;
    background: #282828;
    font-size: 14px;
    padding: 3px 15px;
    margin-left: 10px;
    border-radius: 20px;
}
.tag:hover {
    background: #282828dd;
    box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, .2);
  }

.tag-kw {
    color: #282828;
    border: 1px solid;
    font-size: 0.7em;
    padding: 0.5em 2em;
    margin-left: 7px;
    border-radius: 20px;
}

.tag-kw:hover {
    /* background: #282828dd; */
    box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, .2);
  }

.graph-wrapper {
    overflow-x: scroll !important;       
}

.graph-holder {
    padding: 20px;
}

.title-table {
    margin-top: 40px;
    width: 90%;
}

.title-table tr {
    height: 100px;
    /* border-right:1px solid #efefef; */
}

.title-table tr>th {
    font-size: 18px;
    /* font-family: Quicksand-Bold; */
}

.text-adjust-container {
    width: 100%;
}

.center-contain {
    margin: auto;
    display: flex;
    justify-content: center;
}

.text-adjust-container img {
    width: 50%;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.text-adjust {
    padding: 10px;
    margin: auto;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.text-adjust p {
    text-align: left;
}

.table-row-height {
    height: 200px;
}

.title-table p {
    margin: auto;
    justify-content: center;
    align-items: center;
    line-height: 22px;
    font-size: 16px;
    /* font-family: Quicksand-Bold; */
}

.charge-note {
    /* display: flex; */
    /* justify-content: left;
    text-align: left; */
    font-size: 12px;
    /* font-family: Quicksand-Regular; */
}

.charge-title {
    font-size: 34px;
    
}

.charge-img {
    padding: 20px;
}

.charge-paragrap {
    font-size: 16px;
    
}

.inp-charg {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.inp-charg p {
    font-size: 16px;
    /* font-family: Quicksand-Bold; */
}

.inp-charg input {
    padding: 7px !important;
    border: 1px solid #c4c4c4;
    border-radius: 7px;
    font-size: 16px;
    /* font-family: Quicksand-Bold; */
}

.tr-bg {
    padding: 20px;
    background-color: #007CDE20;
    border-radius: 8px;
}

.capacity-btn {
    padding: 8px 20px;
    background-color: var(--primary);
    color: #ffffff;
    font-size: 16px;
    /* font-family: Quicksand-Regular; */
    border-radius: 5px;
    margin-left: 5px;
}
.title-charging{
  font-size: 15px!important;
  line-height: 20px;
  /* text-align: left; */
  word-break: break-all;
}
.capacity-btn:hover {
    background: #007cdedd;
    box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, .2);
}

.else-div {
    width: 100%;
}

.provider {
    width:100%;
    height:130px !important;
    display: flex;
    margin: auto;
    place-content: center;
    align-items: center;
}

@media only screen and (max-width: 790px) {
    .capacity-inp::after{
        content: "\a";
        white-space: pre;
    }
    /* .provider-wrapper {
        min-width: 180px !important;
    } */
}
@media only screen and (max-width: 480px) {
    /* .provider-wrapper {
        min-width: 100% !important;
    } */

    .filter-text .hidden{
        display: none;
    }
    .break-child::before {
        content: "\A";
        white-space:pre;
    }
    .month {
        display: none;
      }
}

.provider-header {
    /* height: 80px;
    display: grid;
    place-content: center;
    overflow-y: hidden; */
    align-self: center;
    /* min-width: 130px;
    max-width: 130px; */
    font-size: 20px;
    font-weight: bold;
}

.ac-title, .dc-title {
    /* height: 30px; */
    /* border: 1px solid #54DE5D; */
    /* display: grid; */
    /* place-content: center;
    overflow-y: hidden; */
    font-size: 15px;
    font-weight: bold;
}

.ac-title span{
    font-size: 16px !important;
}

.dc-title span{
    font-size: 16px !important;
}

.provider-container {
    display:flex;
    /* overflow: auto; */
}
.provider-wrapper {
    min-width: 200px;
}

.provider-wrapper-no {
  min-width: 350px;
}

.tb-header-height {
  height: 4em;
}
.scrollTab {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

#style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
	height: 10px;
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb
{
	background-color: #414141;
	border: 2px solid #555555;
}

.share-bg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #E0F7F3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-bg:hover {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #cbfdf5;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* -----------------------------------
   TAG BUTTONS
------------------------------------*/

    .btn-active-question {
        color: #FFFFFF;
        background: #0FBEA3;
        font-size: 14px;
        padding: 3px 25px;
        border-radius: 20px;
    }
        
    .btn-active-question:hover {
        background: #0FBEA3dd;
        box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, .2);
    }
        
    .tag-question {
        color: #FFFFFF;
        background: #C4C4C4;
        font-size: 14px;
        padding: 3px 25px;
        border-radius: 20px;
    }
        
    .tag-question:hover {
        background: #C4C4C4dd;
        box-shadow: -1px 6px 10px 0 rgba(120, 60, 20, .2);
    }
    
    .filter-text {
    font-size: 14px;
    padding: 10px;
    }

/* -----------------------------------
   YOUTUBE VIDEO
------------------------------------*/

    .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
    }
    
    .video-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }


/* -----------------------------------
   FOOTER SECTION
------------------------------------*/
.no-padding {
    padding:0!important 
}

.backtoTopBtn {
  border:0px solid red !important;
  position: fixed;
  bottom:10%;
  right: 10%;
  z-index: 120;
  background-color: transparent;
}

.btt-icon {
  background-color: var(--secondary);
  border:1px solid rgb(230, 230, 230);
  width:50px;
  height:50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btt-icon:hover {
  background-color: #00917bf1;
  /* width:52px;
  height:52px; */
}

.go_top {
    line-height:40px;
    cursor:pointer;
    width:40px;
    background:#5867dd;
    color:#fff;
    position:fixed;
    -webkit-box-shadow:0 4px 4px rgba(0,0,0,.1);
    box-shadow:0 4px 4px rgba(0,0,0,.1);
    -webkit-border-radius:50%;
    border-radius:50%;
    right:-webkit-calc((100% - 1140px)/ 2);
    right:calc((100% - 1140px)/ 2);
    z-index:111;
    bottom:80px;
    text-align:center
}

.go_top span {
display:inline-block
}

.footer-big {
padding:105px 0 65px 0
}

.footer-big .footer-widget {
margin-bottom:40px
}

.footer--light {
background:#e7e8ed
}

.footer-big .footer-menu ul li a,.footer-big p,.footer-big ul li {
color:#898b96
}

.footer-menu {
padding-left:48px
}

.footer-menu ul li a {
font-size:15px;
line-height:32px;
-webkit-transition:.3s;
-o-transition:.3s;
transition:.3s
}

.footer-menu ul li a:hover {
color:#5867dd
}

.footer-menu--1 {
width:100%
}

.footer-widget-title {
line-height:42px;
margin-bottom:10px;
font-size:18px
}

.mini-footer {
background:#192027;
text-align:center;
padding:15px 0
}

.mini-footer p {
margin:0;
line-height:26px;
font-size:15px;
color:#999
}

.mini-footer p a {
color:#5867dd
}

.mini-footer p a:hover {
color:#34bfa3
}

.widget-about img {
display:block;
margin-bottom:30px
}

.widget-about p {
font-weight:400
}

.widget-about .contact-details {
margin:30px 0 0 0
}

.widget-about .contact-details li {
margin-bottom:10px
}

.widget-about .contact-details li:last-child {
margin-bottom:0
}

.widget-about .contact-details li span {
padding-right:12px
}

.widget-about .contact-details li a {
color:#5867dd
}

@media (max-width:991px) {
    .footer-menu {
        padding-left:0
    }
}

@media (max-width:576px) {
  .align-left{
    justify-content: flex-start!important;
    margin-top: 10px!important;
  }
}

/* Terms of Use */
.blog-detail h5 {
  font-size: 24px;
  color: #1b1a1a;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  margin: 0 0 28px;
  line-height: 1;
  list-style: none;
  padding: 0;
}

.blog-detail p {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  color: #464646;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.blog-detail h6 {
  font-size: 18px;
  color: #1b1a1a;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  margin: 0 0 28px;
  line-height: 1;
}

.blog-detail li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.blog-detail .custom-table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.blog-detail .table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
}
.blog-detail .custom-table td{
  padding: 0.75rem;
  vertical-align: top;
  background-color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    color: #212529;
}
.card-title {
  height: 45px;
  overflow: hidden;
}
.remove_underline {
  text-decoration: none;
}

.align-image-center{
  text-align: center;
}

.right_1px_border {
  border-right: 1px solid #dee2e6!important;
  /* position: sticky; */
  left: 0;
}
.auto-overflow {
  overflow: auto;
}

.container-provider {
  display: flex;
}
.white-background-color {
  background-color: white;
}
.ac-style-left {
  background: #E0F7F3;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.ac-style-right {
  background: #E0F7F3;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.ac-bg-color {
  background-color: #E0F7F3 !important;
}
.arrow-up {
  width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 18px solid #E0F7F3;
    margin: auto;
}
.provider-padding {
  padding: 0 10px;
}
.span-style {
 font-size: 13px;
 padding-left: 5px;
 color: #555353;
}
.width-200 {
width: 200px!important;
}
p img{
  width: 100%;
}

.border-evprice {
  border: 1px solid #000 !important;
}

.w-h-adj {
  height: 78px !important;
  position: relative;
}

.wd {
  min-width: 85px;
}
.img-control {
  height: 80% !important;
  /* position:absolute; */
  object-fit: contain;
}

.warpper-min-height {
  min-height: 90vh;
  display: block;
}

@media (max-width: 590px) {
  .center-wrap {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 1em;
  }
  }

  .card-min-height-global-news {
    height: 200px !important;
  }

  @media (max-width: 770px) {
    .card-min-height-global-news {
      height: auto !important;
    }
}

.country-number {
  border-bottom: 1px solid #02AF95 !important;
  padding: 0.5em;
}

.pagination-right-position div:nth-child(2){
  justify-content: end !important;
}

.sign-up-btn-style {
  padding: 7px 15px;
  background-color: var(--primary);
  color: #FFFFFF !important;
  border-radius: 15px;
  align-self: center;
}

.sign-up-btn-style:hover {
  background-color: #0091ff;
  color: #FFFFFF !important;
  
}

.error-border {
  border-color: red;
}

.login-btn-style {
  padding: 7px 15px;
  border: 1px solid var(--primary);
  color: var(--primary) !important;
  border-radius: 15px;
  align-self: center;
}

.login-btn-style:hover {
  border: 1px solid #0091ff;
  color: #0091ff !important; 
}

.left-menu-container {
  background-color: var(--primary);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  /* height: 80vh; */
}

.right-menu-container {
  background-color: #ffffff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  /* height: 80vh; */
}

.card-warpper-container {
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid rgb(184, 184, 184);
}

.img-btn:hover {
  opacity: 0.5;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.cursor_underline {
  cursor: pointer;
  text-decoration: underline;
}

.margin_right_left_6 {
  margin-right: 15%!important;
  margin-left: 15%!important;
}

.custom-phone-input .form-control {
  width: 100% !important;
  padding: 7.5px 15px 7.5px 60px !important;
}
.input-error input {
  border-color: red!important;
}
.contact_button {
  font-size: 16px;
  background-color: #007CDE !important; 
  color: #FFFFFF;
  /* border-color: #0FBEA3!important; */
  border-radius: 15px;
  padding: 6px 25px;
  width: 80%;
}

.contact_button:hover{
  background-color: #0091ff!important;
  color: #fff!important;
}

.text-title-box {
  background-color: #007ede2d;
  border-radius: 10px;
  border: 1px solid #007CDE;
  padding: 5px;
}

.card-auto {
  background-color: #02af95a8;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.card-auto img {
  background-color: #02af95a8;
  height: 100%;
  width: 100%;
  z-index: -2;
  object-fit:cover !important;
  /* object-fit: fill; */
  border-radius: 8px;

}

.bg-contain-text-sec {
  background-color: #02af95a8;
  color:#fff;
  position:absolute;
  border-radius: 8px;
  font-size: 0.9em;
  top: 0;
  width: 100%;
  height: 100%;
}

.bg-contain-text-pri {
  background-color: #007cdea8;
  color:#fff;
  position:absolute;
  border-radius: 8px;
  font-size: 0.9em;
  top: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 999px) {
  .navbar-expand-md .navbar-toggler {
      display: block !important;
  }
}

@media (max-width: 999px) {
  
  .navbar-nav {
    display: flex;
    flex-direction: column !important;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .collapse:not(.show) {
    display: none !important;
  }

}

.bg-gray-color {
  background-color: #F2F5F7 !important;
}

.img-dropshadow {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
  /* box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px 0px; */
}

.ev-list-img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
@media (max-width:1000px) {
  .navbar>.container {
    flex-wrap: unset;   
  }
  .navbar .navbar-toggler {
    margin-bottom: auto;
    margin-top: 5px;
    display: block;
  }    
}